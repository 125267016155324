import { html, TemplateResult } from 'lit-html';

import { ProductCardProps, ProductSetProps } from '../../types/productProps';
import ratingStars from '../ratingStars/ratingStars';
import getProductPrice from '../../lib/productPrice';
import { defaultImageIcon } from '../constants';

import {
    productLinkStyle,
    productCardContainer,
    productImageStyle,
    productImageSrcSet,
    productBrandStyle,
    productNameStyle,
    productRatingStyle,
    actionStyle,
    buttonStyle,
    productDefaultImageStyle,
} from './styles';

const productCard = (props: ProductSetProps, productCardProps: ProductCardProps): TemplateResult => {
    const productImageDefault = productCardProps.productImages ? productCardProps.productImages.url1280wide : '';

    const productImageHtml = html`
        <img
            loading="lazy"
            src="${productImageDefault}"
            srcset="${productImageSrcSet(productCardProps)}"
            class="${productImageStyle(productCardProps)}"
        />
    `;

    const defaultProductImage = html`
        <div class="${productDefaultImageStyle(props)}">
            ${defaultImageIcon}
        </div>
    `;

    const productImage = html`
        <a href="${productCardProps.path}" class="${productLinkStyle(props)}">
            ${productCardProps.productImages ? productImageHtml : defaultProductImage}
        </a>
    `;

    const productBrandName = html`
        <div class="${productBrandStyle(props)}" data-test-id="product-set-widget-brand">
            ${productCardProps.brand}
        </div>
    `;

    const productName = html`
        <div class="${productNameStyle(props)}" data-test-id="product-set-widget-name">
            <a href="${productCardProps.path}">${productCardProps.name}</a>
        </div>
    `;

    const productRatings = html`
        <div class="${productRatingStyle(props)}" data-test-id="product-set-widget-ratings">
            ${ratingStars(productCardProps.rating, productCardProps.starColorFilled, productCardProps.starColorEmpty)}
            <span>(${productCardProps.totalReviews})</span>
        </div>
    `;

    const productPrice = getProductPrice(props, productCardProps);

    const productActionButton = html`
        <div class="${actionStyle(props)}">
            <a href="${productCardProps.actionLink}" class="${buttonStyle(props)}">
                ${productCardProps.actionLabel}
            </a>
        </div>
    `;

    return html`
        <div class="${productCardContainer(props)}" aria-label="${productCardProps.name}">
            ${productImage} ${productBrandName} ${productName} ${productRatings} ${productPrice} ${productActionButton}
        </div>
    `;
};

export default productCard;
